/* Images responsives */
.service_thumb img {
    max-width: 100%; /* Les images ne dépasseront pas la largeur de leur conteneur */
    height: auto; /* Maintient le ratio d'aspect de l'image */
}

/* Assure que les images ne touchent pas les autres éléments */
.service_details_area .service_thumb {
    margin: 0 auto; /* Centre les images */
    text-align: center; /* Centre le contenu si nécessaire */
}
