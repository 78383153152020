/* Styles de base */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa; /* Fond blanc */
}

.header-area {
  background-color: #ffffff; /* Fond blanc */
  color: #333; /* Couleur du texte */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Ombre légère */
}

/* Menu mobile */
.menu-toggle {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #498bbe; /* Couleur bleu */
  font-size: 24px;
  cursor: pointer;
}

/* Styles du menu mobile */
.nav-links {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.nav-links.active {
  display: flex; /* Affiche le menu quand il est ouvert */
}

.nav-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-links li {
  padding: 10px 15px;
  transition: background-color 0.3s;
}

.nav-links li:hover {
  background-color: #e7f1ff; /* Couleur au survol */
}

.lang-selector-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: -200px; /* Ajuste selon besoin */
}

/* Pour aller encore plus à droite, ajuste la largeur de l'élément parent */
.header_right {
  display: flex;
  justify-content: flex-end; /* Pousse tous les éléments à droite */
  width: 100%; /* S'assure que le conteneur prend toute la largeur */
}

.lang-selector {
  background-color: #ffffff; /* Fond blanc pour un look épuré */
  border: 1px solid #ccc; /* Bordure grise */
  color: #333; /* Couleur du texte */
  padding: 8px 12px; /* Espacement */
  border-radius: 5px; /* Coins arrondis */
  cursor: pointer; /* Pointeur sur le sélecteur */
  font-weight: 500; /* Légèrement plus gras */
  font-size: 14px; /* Taille de la police */
  transition: border-color 0.3s, background-color 0.3s; /* Transition douce */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombre légère */
}

.lang-selector:hover {
  background-color: #f0f8ff; /* Couleur de fond douce au survol */
  border-color: #498bbe; /* Changement de couleur de la bordure au survol */
}

.lang-selector:focus {
  outline: none; /* Retire le contour par défaut */
  border-color: #498bbe; /* Bordure bleu clair lorsqu'il est actif */
  box-shadow: 0 0 5px rgba(73, 139, 190, 0.5); /* Ombre légère au focus */
}

.lang-selector option {
  background-color: #ffffff; /* Fond blanc */
  color: #333; /* Couleur du texte */
}

@media (max-width: 768px) {
  .header-top_area .container .row {
    display: flex;
    align-items: center; /* Centre les éléments verticalement */
  }
  .col-12 {
    display: flex; /* Utilise Flexbox pour la colonne */
    align-items: center; /* Centre les éléments verticalement */
    justify-content: space-between; /* Espace entre le logo et le bouton */
  }

  .menu-toggle {
    margin-left: auto; /* Pousse le bouton vers la droite */
    color: #498bbe; /* Couleur bleu */
    font-size: 24px;
    cursor: pointer;
    border: none; /* Supprime la bordure */
    background: none; /* Supprime l'arrière-plan */
  }

  /* Autres styles pour le menu */
  .nav-links {
    display: none; /* Cache le menu par défaut */
    flex-direction: column;
    position: absolute;
    top: 60px; /* Ajuste selon la hauteur de ta navbar */
    left: 0;
    right: 0; /* Prend toute la largeur */
    background-color: #ffffff; /* Fond blanc pour le menu */
    padding: 1rem 0;
    z-index: 1000;
  }

  .nav-links.active {
    display: flex; /* Affiche le menu quand il est ouvert */
  }


  .nav-links li {
    width: 100%; /* Prend toute la largeur */
    text-align: center; /* Centre le texte des éléments du menu */
  }

  .lang-selector-container {
    margin: 10px 0; /* Marge pour espacer le sélecteur en mobile */
    justify-content: center; /* Centre le sélecteur dans le menu mobile */
    width: 100%; /* Prend toute la largeur pour le menu mobile */
  }

  .lang-selector {
    width: 50%; /* Diminuer la largeur du sélecteur */
    max-width: 100px; /* Limite la largeur maximale */
    padding: 10px; /* Plus de padding pour le confort sur mobile */
    font-size: 16px; /* Augmente la taille de police */
    margin: 0 auto; /* Centre le sélecteur */
    border-radius: 5px; /* Coins arrondis */
    border: 1px solid #ccc; /* Bordure grise */
    background-color: #ffffff; /* Fond blanc */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombre légère */
  }

  .short_contact_list ul {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .short_contact_list li {
    margin-bottom: 10px; /* Espace entre les liens */
    text-align: center; /* Centre le texte */
  }

  .short_contact_list a {
    color: #498bbe; /* Couleur des liens */
    text-decoration: none;
  }
}



